/* eslint-disable @next/next/no-html-link-for-pages */
const AboutSenegal = () => {
  return (
    <section className="section-bg layout-pt-lg layout-pb-lg">
      <div className="section-bg__item -right -w-1165 bg-light-2" />
      <div className="section-bg__item -video-left">
        <div className="row y-gap-30">
          <div className="col-sm-6">
            <img src="/img/video/about-memorial-joola-1.jpg" alt="about-memorial-joola" />
          </div>
          {/* End .col */}

          <div className="col-sm-6">
            <img src="/img/video/about-memorial-joola-2.jpg" alt="about-memorial-joola" />
          </div>
        </div>
        {/* End .row */}
      </div>
      {/* End .section-bg__item */}

      <div className="container lg:mt-30">
        <div className="row">
          <div className="offset-xl-6 col-xl-5 col-lg-6">
            <h2 className="text-30 fw-600">Mémorial du Bateau le JOOLA</h2>
            <p className="text-dark-1 mt-40 lg:mt-20 sm:mt-15" style={{ textAlign: 'justify' }}>
              Le service du Mémorial du Bateau Le Joola est chargé d&apos;ériger le Mémorial du Bateau le Joola qui constituera un lieu de recueillement pour les parents et familles de victimes.  En outre, sa perception d&apos;être un projet social de par la symbolique et la sensibilité, il sera un projet structurant permettant d&apos;une part d&apos;accroitre la valeur ajoutée de la région dans le PIB et d&apos;autre part, de constituer un cadre fédérateur pour la paix en Casamance.
              Autrement, il a pour mission d&apos;immortaliser la tragédie du Bateau le JOOLA par la matérialisation d&apos;actions significatives pertinentes à la préservation de la mémoire collective.

            </p>
            <div className="d-inline-block mt-40 lg:mt-30 sm:mt-20">
              <a
                  href="/memorial/presentation/lejoola"
                className="button -md -blue-1 bg-yellow-1 text-dark-1"
              >
                Lire plus <div className="icon-arrow-top-right ml-15" />
              </a>
            </div>
          </div>
        </div>
        {/* End .row */}
      </div>
      {/* End .col */}
    </section>
  );
};

export default AboutSenegal;
